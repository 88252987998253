body, p {
    font-size: 16px;
    line-height: normal;
}

body {
    font-family: 'Lato', sans-serif;
}

.setFont {
    font-family: 'Lato', sans-serif;
}

.tile {
    font-size: 16px;
}

.tile-icon {
    font-size: 25px;
}
.no-window .tile {
    font-size: 16px;
}
.no-window .tile-icon {
    font-size: 25px;
}



@media only screen and (min-height: 800px) and (min-width: 1600px) {
    .no-window .tile {
        font-size: 20px;
    }
    .no-window .tile-icon {
        font-size: 35px;
    }
}

@media only screen and (max-height: 700px) {
    .no-window .tile, .tile {
        font-size: 14px;
    }
    .no-window .tile-icon, .tile-icon {
        font-size: 22px;
    }
}