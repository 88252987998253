.wrapper * {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}

.grid-wrapper.do-entry .tile {
    animation: entrance 0.5s ease-out normal backwards;
}

.grid-wrapper .tile {
    animation: breathing 1s ease-in-out normal backwards infinite;
}

.animateWrapper {
    animation: fadeIn 0.5s ease-out normal backwards;
}

.animateImg, button {
    animation: entranceLow 400ms ease-in-out normal backwards;
}

.enterRight {
    animation: enterR 400ms ease-in-out normal backwards;
}

.grid-wrapper .tile:hover {
    animation: grow 100ms ease-in normal forwards;
    animation-delay: 0ms!important;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.267);
}

.grid-wrapper.no-window .no-link{
    pointer-events: none;
}

.grid-wrapper .active {
    animation-delay: 0s!important;
    pointer-events: none;
    background-color:#027aff;
    color: #fff;
}


@media only screen and (min-width: 850px) {
    .wrapper {
        transition: 200ms;
    }
    .grid-wrapper.do-entry .tile {
        animation: entrance 0.5s ease-out normal backwards!important;
    }
    .grid-wrapper.do-exit .tile {
        animation: exit 200ms ease-out normal forwards!important;
    }
    .wrapper.do-entry .window-wrapper {
        animation: normal-entrance 0.5s ease-out normal forwards!important;
    }
    .wrapper.do-exit .window-wrapper {
        animation: exit 500ms ease-out normal forwards!important;
    }
}

@keyframes enterR {
    0% {
        transform: translateX(100px);
        opacity: 0.5;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes grow {
    to {
        transform: scale(1.05);
    }
}

@keyframes breathing {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.98);
    }
    55% {
        transform: scale(0.98);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes normal-entrance {
    0% {
        transform: scale(0);
    }
    20% {
        transform: scale(0.7);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes exit {
    0% {
        transform: scale(1);
    }
    70% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes entranceLow {
    0% {
        transform: scale(0.8);
    }
    60% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes entrance {
    0% {
        transform: scale(0);
    }
    70% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}