.grid-wrapper {
    display: grid;
    width: 100%;
    gap: 20px;
    color: #181815;
    min-height: 0;
    min-width: 0;
}




.tile {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 1em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: inherit;
}




.tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0 10px;
    transition: all 200ms linear;
}
.tile.no-link{
    cursor: default;
}
.social-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: inherit;
}

.social-wrapper .tile {
    min-width: auto!important;
    width: auto!important;
    gap: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tile.show-on-full{
    display: none;
}
.img-tile img {
    object-fit: scale-down;
    height: 100%;
    object-position: top;
    width: 300px;
    object-fit: cover;
    width: auto;
    max-width: 60px;
}

.no-window .img-tile img {
    max-width: min-content;

}

    .tile.show-on-full{
        display: flex;
    };