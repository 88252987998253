.skills-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1em;
    height: 100%;
}

.type-wrapper{
    display: flex;
    flex-direction: column;
    
}

.skills-wrapper .content{
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}
.head{
    margin-bottom: 20px;
}
.skill{
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.338);
    padding: 0.5em;
    margin: 0.5em;
    display: grid;
    grid-template-columns: 0fr 1fr;
    border-radius: 5px;
}
.skill .icon{
    display: inline-block;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.skill .name{
    display: flex;
    align-items: center;
    justify-content: center;
}
.cnt-wrp{
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
}
@media only screen and (max-width: 500px) {
    .skills-wrapper{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: 1em;
        height: 100%;
    }
}

